import React from "react"
import "../styles/styles.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import backArrow from '../images/back-arrow.svg'
import { Helmet } from "react-helmet"

//Import Components
import Header from "../components/header"
import Footer from "../components/footer"

const MissingPage = () => (
  <div>
    <Helmet>
      <title>Eric Lam — User Experience Designer</title>
    </Helmet>
    <Header/>
    <div className = "background-white">
      <div className = "home-container">
          <div className = "home-left dark-gray-text">
            <div className = "summary-header">Oops!</div>
            <div className = "summary-text">
              We can't seem to find the page you are looking for.
            </div>
            <AniLink className = "button-project button-project-dark-gray" to ="../" duration = {0.3}>
              <img className = "back-arrow" src={backArrow} alt="Back Arrow"/>
                Go Home
            </AniLink>
          </div>
          <div className = "home-right" >
          </div>
      </div>
    </div>
    <Footer />
  </div>
)

export default MissingPage
